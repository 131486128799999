html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

// .login-card {
//   padding: 40px;
//   padding-top: 0px;
//   padding-bottom: 10px;
//   width: 274px;
//   background-color: #F7F7F7;
//   margin: 0 auto 10px;
//   border-radius: 2px;
//   box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
//   overflow: hidden;
// }

// .login-card+.login-card {
//   padding-top: 10px;
// }

// .login-card h1 {
//   font-weight: 100;
//   text-align: center;
//   font-size: 2.3em;
// }

// .login-card [type=submit] {
//   width: 100%;
//   display: block;
//   margin-bottom: 10px;
//   position: relative;
// }

// .login-card input[type=text],
// input[type=email],
// input[type=password] {
//   height: 44px;
//   font-size: 16px;
//   width: 100%;
//   margin-bottom: 10px;
//   -webkit-appearance: none;
//   background: #fff;
//   border: 1px solid #d9d9d9;
//   border-top: 1px solid #c0c0c0;
//   padding: 0 8px;
//   box-sizing: border-box;
//   -moz-box-sizing: border-box;
// }

// .login {
//   text-align: center;
//   font-size: 14px;
//   font-family: 'Arial', sans-serif;
//   font-weight: 700;
//   height: 36px;
//   padding: 0 8px;
// }

// .login-submit {
//   border: 0px;
//   color: #fff;
//   text-shadow: 0 1px rgba(0, 0, 0, 0.1);
//   background-color: #4d90fe;
// }

// .google-button {
//   height: 40px;
//   border-width: 0;
//   background: white;
//   color: #737373;
//   border-radius: 5px;
//   white-space: nowrap;
//   box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
//   transition-property: background-color, box-shadow;
//   transition-duration: 150ms;
//   transition-timing-function: ease-in-out;
//   padding: 0;
// }

// .google-button:focus,
// .google-button:hover {
//   box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.1);
// }

// .google-button:active {
//   background-color: #e5e5e5;
//   box-shadow: none;
//   transition-duration: 10ms;
// }

// .google-button__icon {
//   display: inline-block;
//   vertical-align: middle;
//   margin: 8px 0 8px 8px;
//   width: 18px;
//   height: 18px;
//   box-sizing: border-box;
// }

// .google-button__icon--plus {
//   width: 27px;
// }

// .google-button__text {
//   display: inline-block;
//   vertical-align: middle;
//   padding: 0 24px;
//   font-size: 14px;
//   font-weight: bold;
//   font-family: 'Roboto', arial, sans-serif;
// }

// .login-card a {
//   text-decoration: none;
//   color: #666;
//   font-weight: 400;
//   text-align: center;
//   display: inline-block;
//   opacity: 0.6;
// }

// .login-help {
//   color: #666;
//   width: 100%;
//   text-align: center;
//   font-size: 12px;
// }

// .login-client-image img {
//   margin-bottom: 20px;
//   display: block;
//   margin-left: auto;
//   margin-right: auto;
//   width: 20%;
// }

// .login-card input[type=checkbox] {
//   margin-bottom: 10px;
// }

// .login-card label {
//   color: #999;
// }

// .grant-debug {
//   text-align: center;
//   font-family: Fixed, monospace;
//   width: 100%;
//   font-size: 12px;
//   color: #999;
// }

// .grant-debug div {
//   padding-top: 10px;
// }

// .login-help+form {
//   margin-top: 10px;
// }

// ul {
//   font-weight: 100;
//   padding-left: 1em;
//   list-style-type: circle;
// }

// li+ul,
// ul+li,
// li+li {
//   padding-top: 0.3em;
// }

// button {
//   cursor: pointer;
// }
